.public .left .form-wrapper .form {
    display: block !important;
}

.public .left .flex.logo img {
  width: 100%;
}

.public .left .flex.logo.default img {
  width: 160px;
}

#sharable-reward .badge-image {
  width: 80%;
  margin-block-start: 50px;
}

#sharable-reward .certificate-image {
  width: 550px;
  margin-block-start: 50px;
}

.preview {
  position: relative;
  margin-block-start: 50px;
  }


#sharable-reward .student,
#sharable-reward .creator {
  font-size: var(--titleSize);
  font-weight: bold;
  color: var(--textColor);
  margin-top: 15px;
}

#sharable-reward .text {
  color: var(--textColor);
  font-size: var(--subtitleSize);
}

#sharable-reward .badge-wrapper {
  width: 100%;
  text-align: center;
}

#sharable-reward .badge-wrapper > * {
  max-width: 100%;
}

.skeletonBox {
  display: flex;
  position: relative;
  top: 54px;
  justify-content: center;
  margin-block-end: 80px;
}

#sharable-reward .title{
  margin-top: 30px;
  color: var(--titleColor);
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  display: flex;
  position: relative;
  top: 15px;
  justify-content: center;
}

#sharable-reward .container-organization-course-mobile{
  display: none;
}

.exclamation-icon {
  display: flex;
  position: relative;
  top: -25px;
}

.near-logo{
  width: 20px;
  height: 20px;
}

#sharable-reward .organization-desktop{
  margin-top: 10px;
  color: var(--textColor);
  font-size: 20px;
  font-weight: bold;
}
#sharable-reward .organization-mobile{
display: none;
}

#sharable-reward .course-desktop{
  margin-top: 10px;
  color: var(--courseFontColor);
  font-size: 18px;
  line-height: auto;
}


#sharable-reward .course-desktop a {
  color: var(--courseFontColor);
  text-decoration-color: var(--courseFontColor);
  padding-left:  10px;
}

#sharable-reward .note {
  color: #68707b;
  text-align: center;
  font-size: 14px;
}

#sharable-reward .container-description {
  display: flex;
  margin: 40px 0 40px 0;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
  padding: 10px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  white-space: pre-line;
  font-size: var(--descriptionSize);
}


.metadata-container {
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 30%);
  grid-gap: 10px;
  justify-content: space-around;
  white-space: pre-line;
}

.issued-display {
 background-color: #fff !important ;
}

.metadata-preview {
  border-radius: 10px;
  background-color: var(--metadataBoxColor);
  color: var(--metadataTextColor);
}

.metadata-preview h4,
.metadata-preview p {
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#sharable-reward .block h4 {
  margin:  0;
}

#sharable-reward .block p {
  color: #68707b;
  margin: 10px 0 0;
}

.public .no-account {
  padding-bottom: 60px;
}

.public .no-account a {
  text-decoration: underline;
}

.container-share-icons{
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
.share-label {
  display: flex;
  position: relative;
  font-family: 'Syne', sans-serif;
  font-size: 16px;
  margin: 0;
  justify-content: center;
  margin-block-end: 20px;
}

.share-modal-p {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.share-modal-label {
  text-align: center;
  margin: 0;
}

.description-share-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.button-container{
  display: flex;
  position: relative;
  justify-content: center;
  padding: 15px 0px 30px 0px;
}

.label-sold {
  text-align: center;
}

.buy-info-container {
  padding: 5px;
}
