.badge-card.double {
  grid-column: span 2;
}

.badge-card img {
    max-height: 160px;
    object-fit: contain;
    max-width: 100%;
}

.badge-card.double img {
  max-height: 240px;
}
.badge-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: auto;
  color: #1a1d1f;
}

.badge-image {
  text-align: center;
}

.backdrop-badge-image {
  max-width: 65%;
  max-height: 80% !important;
}

.badge-stats {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;

  min-height: 60px;
  align-items: center;
}

.badge-stats .stats p {
    margin: 0;
}

.badge-stats .stats .stat-number {
  text-align: center;
  /*vertical-align: top;*/
  font-size: 20px;
  /*line-height: auto;*/
  margin-bottom: 8px;
  color: #8f8f8f;
}

.badge-stats .stats .stat-title {
  text-align: center;
  /*vertical-align: top;*/
  font-size: 12px;
  /*line-height: auto;*/
  color: #c4c4c4;
}
.stat-title {
  text-transform: uppercase;
} 

.badge-stats .button-wrapper {
  grid-column: span 2;
  text-align: center;
}

.badge-labels {
  position: absolute; 
}

.badge-labels .template, 
.badge-labels .public {
  border-radius: 3px;
  color: var(--orange);
  border-color: var(--orange);
  display: inline-flex;
  position: relative;
  left: -10px;
  top: -10px;
  margin-right: 5px;
  font-size: 12px;
  height: 22px;
  border: 2px solid;
  font-weight: 700;
}
.badge-labels .preview {
  border-radius: 3px;
  color: var(--orange);
  border-color: var(--orange);
  display: inline-flex;
  position: relative;
  left: 10px;
  top: -60px;
  margin-right: 5px;
  font-size: 12px;
  height: 22px;
  border: 2px solid;
  font-weight: 700;

}

.badge-labels span {
  padding: 5px;
}

/* Actions */
.badge-actions-wrapper {
    position: absolute;
    right: 2px;
    top:  2px;
}

.badge-actions-menu .MuiMenuItem-root > svg {
  padding-right: 15px;
}

/* Video */

.video-thumb {
    max-width: 100%;
    margin: 25px 0px 15px 0px;
  }


.container-video {
  display: flex;
  position: relative;
  align-items: center;
  margin: 25px 0px 0px 0px;
  justify-content: flex-start;
}

.video {
  max-width: 100%;
}

.button-play{
  display: flex;
  position: absolute;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-inline-start: 40px;
  right: 25px;
}

.button-audio {
  display: flex;
  position: relative;
  cursor: pointer;
  align-self: normal;
  right: 98%;
  border: none;
  width: 45px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.grey{
  background-color: #ebebeb !important;
}