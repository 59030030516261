.offerts-card-container {
    background-color: #ffffff;
    border-radius: var(--radius);
    padding: 5px;
    position: relative;
    display: flex;
    margin: 10px;
    justify-content: space-around;
  }

  .image-container {
    position: relative;
    max-width: 250px;
    padding: 15px 20px 0 25px;
    display: flow-root;
    margin-block-start: 110px;
    margin-block-end: 15px;
  }

  .offerts-card-img {
    max-width: 100%;
  }
  .offerts-card-title {
    font-size: 30px;
  }

  .offerts-card-text {
    display: block;
    position: relative;
    padding: 5px;
    width: 100%;
}

.cards-header {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  padding: 5px 25px 0px; 
}

  .offerts-card-course {
    margin: 0;
    font-style: italic;
    color: grey;
    display: flex;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 10px 25px;
  }

  .offerts-card-seccond-h3{
    padding-left: 10px;
    margin: 0;
  }

  .offers-card-eligible-rewards-container {
    margin-bottom: 30px;
  }

  .offerts-card-owning {
    padding-left: 12px;
    margin-top: 0px;
  }

  .offerts-card-footer {
    display: inline-flex;
    gap: 15px;
    padding-left: 12px;
  }

  .offerts-card-description {
    padding: 10px;
    display: flex;
    position: relative;
    margin-top: 85px;
    white-space: break-spaces;
  }

  .offerts-card-redeemed-footer {
    display: flex;
    gap: 15px;
    color: green;
    padding-left: 10px;
    align-items: center;
  }
