@media only screen and (max-width: 1024px) {

  /*PUBLIC REWARD*/

  #sharable-reward .organization-mobile {
    color: var(--textColor);
    font-size: 18px;
    display: flex;
    position: relative;
    top: 1px;
  }

  #sharable-reward .organization-desktop  {
    display: none;
  }

  #sharable-reward .course-mobile,
    .course-mobile a {
    color: var(--textColor);
    font-size: 18px;
    text-decoration-color: var(--textColor);
  }

  #sharable-reward .course-desktop{
    display: none;
  }

  #sharable-reward .title {
    font-size: 25px;
    justify-content: flex-start;
    margin-block-end: 25px;
  }
  
  #sharable-reward .title .exclamation-icon {
    top: 0;
  }

  #sharable-reward .title button {
    padding: 0 0 0 8px;
    margin: 0;
  }

  #sharable-reward .container-organization-course-mobile {
    display: flex;
    position: relative;
    grid-gap: 6px;
    gap: 6px;
    flex-wrap: wrap;
    top: 10px;
  }

  #sharable-reward .container-organization-course-desktop {
    display: none;
  }

  /*PUBLIC REWARD*/
  .metadata-container {
    border-radius: 10px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 48%);
    grid-gap: 10px;
    justify-content: space-around;
  }

  .auth-modal-p {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    font-size: 0.90rem;
  }

  .celebration-image{
      width: 100%;
  }

  .page-top {
    display: flex;
    margin-top: 100px;
    width: 100%;
  }

  .page-info {
    display: flex;
    flex-direction: column;
  }
    
  .badges-grid {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }

  main.grid {
    width: 100%;
    margin: 0 !important;
  }

  .page-description {
    font-size: 12px !important;
  }

  #sharable-reward .student{
    font-size: 24px;
  }

  .hidden {
    display: none !important;
  }

  .reward-creation-container{
    flex-direction: column;
  }

  /*PUBLIC REWARD*/   
    
  .reward-preview{
    width: auto;
    height: auto;
    flex-direction: column;
    padding: 15px 20px 20px;
  }

  .description-share-modal {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }

  .left .form-wrapper {
    width: 599px;
    display: flex;
    flex-direction: column;
    padding: 15px 20px 160px;
  }

  #sharable-reward .badge-image {
    width: 100%;
    height: auto;
    margin-block-start: 30px;
  }

  #sharable-reward .container-description {
    display: flex;
    margin: 30px 0 40px 0;
    gap: 5px;
    padding: 10px;
    flex-direction: column;
    white-space: pre-line;
  }

  /* Video */

  .container-video {
    display: flex;
    position: relative;
    text-align: center;
    align-items: center;
    margin: 20px 0px 15px 0px;
  }

  .button-audio {
    display: flex;
    position: relative;
    cursor: pointer;
    align-self: normal;
    right: 98%;
    border: none;
    height: 10%;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .video,
  .video-thumb {
    max-width: 100%;
  }


  /*REDEEM PAGE*/
  .offerts-card-container {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 15px;
    text-align: center;
  }

  .image-container {
    margin-block-start: 0;
    max-inline-size: 300px;
  }

  .cards-header {
    display: flex;
    position: inherit;
    align-items: center;
    width: -webkit-fill-available;
    padding: 5px 25px 0px;
    flex-direction: column;
  }

  .offerts-card-title {
    font-size: 20px;
  }

  .offerts-card-course {
    margin: 0;
    font-style: italic;
    color: grey;
    display: flex;
    padding: 10px 25px;
    position: relative;
    top: -75px;
  }

  .offerts-card-description {
    padding: 15px;
    display: flex;
    position: relative;
    top: -140px;
}

.offerts-card-seccond-h3 {
  margin: 0;
  display: flex;
  justify-content: center;
  padding: 0;
}

.offers-card-eligible-rewards-container {
  position: relative;
  top: -150px;
}

.offers-card-eligible-rewards-list {
  list-style: none;
  padding: 0;
}

.offerts-card-footer {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  padding-left: 12px;
  flex-direction: column-reverse;
  position: relative;
  top: -150px;
}

.offerts-redeemd {
  display: flex;
  position: relative;
}

.offerts-card-redeemed-footer {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  color: green;
  padding-left: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  top: -150px;
  flex-direction: column;
}



}

/* TVI THEME */
@media only screen and (max-width: 1900px) {
  .layout-centered .flex.left {
    background-size: 75%;
  }
}

@media only screen and (max-width: 1400px) {
  .layout-centered .flex.left {
    background-size: 95%;
  }
}

@media only screen and (max-width: 1000px) {
  .layout-centered .flex.left {
    background-image: none;
  }
}