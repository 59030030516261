#root, html, body {
    height: 100%;
    margin: 0;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

.flex.login,
.flex.left {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

}

.layout-centered .flex.left {
  background-image: var(--supportImage);
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: 50%;
  background-position: center;
}


.flex.left {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 60%;
    flex: 1 0 60%;
    overflow-y: auto;
    -webkit-box-shadow: 0 20px 110px 0 rgb(0 12 44 / 8%);
    box-shadow: 0 20px 110px 0 rgb(0 12 44 / 8%);
    z-index: 2;
    background-color: var(--primaryBackgroundColor);
    justify-content: center;
  }

.left .form-wrapper{
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: var(--paddingPage);
  text-align: var(--textAlign);
}

.left .form-wrapper .form {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.left .form-wrapper .form form {
  max-width: 400px;
  margin-top: 64px;
}

.left .form-wrapper .form form .MuiFormControlLabel-root {
  margin-top: 16px;
  margin-bottom: 8px;
}

.flex.right {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 40%;
  flex: 1 0 40%;
  position: relative;
  display: var(--sideBarDisplay);
  z-index: 1;

  background-image: var(--supportImage);
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: bottom right;
}

.flex.logo img {
    width: var(--bannerSize);
}

.form-title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
}

.no-account {
  margin-top: 70px;
  color: var(--textColor);
  font-size: 14px;
  font-weight: 300;
}
.no-account-modal {
  margin-top: 10px;
}

.no-account a,
.label-terms a,
.label-privacy a,
.no-account-modal a {
  color: var(--textColor);
}

.footer-labels {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  white-space: break-spaces;
  letter-spacing: 0.2px;
  color: #68707b;
  font-size: 14px;
  font-weight: 300;
}
.footer-labels-invite {
  top: 90px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  white-space: break-spaces;
  letter-spacing: 0.2px;
  color: #68707b;
  font-size: 14px;
  font-weight: 300
}

.label-in-checkbox a {
  color: #000000;
  text-decoration: underline;
}

.creator {
  font-size: 18px;
  color: grey;
  margin: 0;
}

.flex button {
  margin-top: 20px;
}

.info-div {
  color: var(--textColor);
  text-align: center;
  margin-top: 30px;
  padding-bottom: 0px;
  font-size: 14px;
  font-weight: 300;
}